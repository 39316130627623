import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"
const NotFoundPage = () => (
  <Layout title="Thank You">
    <SEO title="Thank You" />
    <div id="ts-page">
      <Container className="thank-you-page text-center" fluid>
        <Container>
          <h2 className="text-white">Thank you!</h2>
          <h3 className="text-white">
            We have received your message and will get back to you shortly.
          </h3>
          <Link to="/" className="white-btn border-none mt-5">
            Back to Home
          </Link>
        </Container>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
